import React, { useState } from "react"
import emailjs from "emailjs-com"
import logo from './images/logo-mws.png'

const App = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [newsletterConsent, setNewsletterConsent] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault()

    if (!newsletterConsent) {
      setError("Je moet akkoord gaan met de nieuwsbrief om de checklist te ontvangen.")
      return
    }

    const templateParams = {
      user_name: name,
      user_email: email,
    };

    setLoading(true) // Zet loading aan

    emailjs
      .send(
        "service_trv38ui", // Service ID
        "template_de75wpr", // Template ID
        templateParams,
        "qXdIeoNfCPumc-MDK" // User ID
      )
      .then(
        (response) => {
          console.log("E-mail succesvol verzonden!", response.status, response.text)
          setSubmitted(true)
          setName("")
          setEmail("")
          setNewsletterConsent(false)
          setLoading(false) // Zet loading uit
        },
        (err) => {
          console.error("Er ging iets mis:", err)
          setError("Sorry, er is iets misgegaan. Probeer het opnieuw.")
          setLoading(false) // Zet loading uit bij error
        }
      )
  }

  return (
    <div style={styles.container}>
      <img src={logo} alt="Marbel Web Studios Logo" style={styles.logo} />
      <h1 style={styles.heading}>Ontvang de checklist!</h1>
      <p style={styles.text}>
        Vul hieronder je voornaam en e-mailadres in om direct onze handige checklist te ontvangen.
      </p>

      {submitted ? (
        <p style={styles.successMessage}>Bedankt! Je checklist is onderweg. Niet ontvangen? Controleer ook even je spam- of junkfolder.</p>
      ) : (
        <form onSubmit={sendEmail} style={styles.form}>
          <input
            type="text"
            placeholder="Je voornaam"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={styles.input}
            required
          />
          <input
            type="email"
            placeholder="Je e-mailadres"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={styles.input}
            required
          />
          
          <label style={styles.checkboxLabel}>
            <input
              type="checkbox"
              checked={newsletterConsent}
              onChange={(e) => setNewsletterConsent(e.target.checked)}
              style={styles.checkbox}
            />
            Ja, ik wil me ook inschrijven voor de nieuwsbrief!
          </label>

          <button type="submit" style={styles.button} disabled={loading}>
            {loading ? "Verzenden..." : "Verstuur"}
          </button>
        </form>
      )}
      {error && <p style={styles.errorMessage}>{error}</p>}
    </div>
  )
}

// Styling
const styles = {
  container: {
    fontFamily: "'Poppins', sans-serif",
    maxWidth: "400px",
    margin: "50px auto",
    textAlign: "center",
    padding: "80px",
    borderRadius: "15px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  },
  logo: {
    maxWidth: "100%",
    height: "auto",
    marginBottom: "20px",
  },
  heading: {
    fontSize: "26px",
    marginBottom: "15px",
    fontWeight: "600",
    color: "#2E2E2E",
    fontFamily: "'Bebas Neue', cursive",
  },
  text: {
    fontSize: "16px",
    marginBottom: "20px",
    color: "#555",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    padding: "12px",
    fontSize: "16px",
    width: "100%",
    marginBottom: "15px",
    borderRadius: "8px",
    border: "1px solid #A0C0A9",
    transition: "border-color 0.3s",
  },
  inputFocus: {
    borderColor: "#57827E",
    outline: "none",
  },
  checkboxLabel: {
    fontSize: "14px",
    marginBottom: "15px",
    display: "flex",
    alignItems: "center",
  },
  checkbox: {
    marginRight: "10px",
  },
  button: {
    padding: "12px 20px",
    fontSize: "16px",
    color: "#fff",
    backgroundColor: "#A0C0A9",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s, transform 0.3s",
  },
  buttonHover: {
    backgroundColor: "#A0C0A9",
    transform: "translateY(-2px)",
  },
  successMessage: {
    color: "#4CAF50",
    fontSize: "18px",
  },
  errorMessage: {
    color: "red",
    fontSize: "16px",
  },
};

export default App;
